import React, { Component } from 'react'
import { Link } from 'gatsby-plugin-react-i18next';
import '../../styles/scss/header.scss'
import { disableScroll, reinitScroll } from '../../../data/util';

export default class Header extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            isExpanded: false,
            menu: [
                {
                    title: 'Verse World',
                    url: '/#verse-world',
                    id: 'verse-world',
                    active: true
                },
                {
                    title:"Services",
                    url: "/#scanning-3D",
                    id: 'scanning-3D',
                    subelements:[
                        {
                            title:"3D scanning",
                            url:"/#scanning-3D",
                            id: 'scanning-3D',
                        },
                        {
                            title:"AR/MR/VR",
                            url:"#ar-mr-vr",
                            id: 'ar-mr-vr',
                        },
                        {
                            title:"Motion Capture",
                            url:"/#motion-capture",
                            id: 'motion-capture',
                        },
                        {
                            title:"3D Modeling",
                            url:"/#modeling-3D",
                            id: 'modeling-3D',
                        },
                        {
                            title:"Live Performance",
                            url:"/#live-performance",
                            id: 'live-performance',
                        }
                    ],
                },
               /* {
                    title: 'Events',
                    url: '/#events-physical',
                    id: 'events-physical',
                },*/
                {
                    title: 'Games',
                    url: '/#games',
                    id: 'games',
                },
                /*{
                    title: 'Academy',
                    url: '/academy',
                    id: 'academy',
                },*/
                /*{
                    title: 'News',
                    url: '/news',
                    id: 'news',
                },*/
                {
                    title: 'About us',
                    url: '/#about',
                    id: 'about',
                },
                {
                    title: 'Join Us',
                    url: '/#join-us',
                    id: 'join-us',
                },
                
            ],
            socials : [
                {
                    icon:'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/480px-Instagram_icon.png',
                    url:'https://www.instagram.com/verseworld'
                },
                {
                    icon:'https://cdn-icons-png.flaticon.com/512/174/174857.png',
                    url:'https://ae.linkedin.com/company/verse-estate'
                },
                {
                    icon:'https://cdn-icons-png.flaticon.com/512/124/124021.png',
                    url:'https://x.com/VerseWorld'
                },
            ],
            scrolling: true,
        }
    }

    componentDidMount = () => {
        
    }

    initIntersectionObserver = () => {
        const onScreen = new Set();
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                const intersecting = entry.isIntersecting
                if (intersecting) {
                    this.setActiveElement(entry?.target?.id)
                }
            })
        }, {threshold: this.props.threshold || .6})

        if (this.state.menu?.length)
            for (let menuElement of this.state.menu) {
                if (!menuElement?.subelements?.length && document.getElementById(menuElement?.id))
                    observer.observe(document.getElementById(menuElement?.id))
                else if (menuElement?.subelements?.length) {
                    for (let subElt of menuElement?.subelements) {
                        if (document.getElementById(subElt?.id)) {
                            observer.observe(document.getElementById(subElt?.id))
                        }
                    }
                }
            }
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.isLoaded !== prevProps.isLoaded && !prevProps.isLoaded) {
            this.initIntersectionObserver()
        }
    }

    setActiveElement = (id) => {
        if (!this.state.menu?.length) return;
        this.setState({
            menu: this.state.menu.map((element) => {
                element.active = element.id === id;
                if (element?.subelements?.length) {
                    for (let subElt of element?.subelements) {
                        subElt.active = false;
                        if (subElt?.id === id) {
                            element.active = true;
                            subElt.active = true;
                        }
                    }
                }
                return element
            })
        })
    }

    isFromHomepage = () => {
        return window.location.pathname === '/' || window.location.pathname === '/coming-soon'
    }

    activateMenu = () => {
        this.setState({isExpanded:!this.state.isExpanded}, () => {
            if (this.state.isExpanded)
                disableScroll()
            else
                reinitScroll()
        });
    }

    renderMenuContainer = () => {
        return (
            <>
                <Link to={'/'} className='logo-container'>
                    <div className='logo-official normal'/>
                </Link>
                <div className={"menu-wrapper ".concat(this.state.isExpanded ? "expanded " : "")}>
                    <div className="options " id='menu'>
                    <div className={"hamburger ".concat(this.state.isExpanded ? "menu-expanded" : "")} onClick={this.activateMenu}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                        {this.renderMenuContent()}
                    </div>
                </div>
            </>
        )
    }

    renderSubMenuContent = (subElements) => {
        if (subElements?.length) {
            return (
                <div className='navbar-dropdown-menu'>
                    {subElements.map((subElement, index) => {
                        return (
                            <Link to={subElement?.url}
                                className={'navbar-dropdown-menu-item' + (subElement?.active ? ' active' : '')}
                                key={index}
                                onClick={this.activateMenu}
                            >
                                {subElement.title}
                            </Link>
                        );
                    })
                    }
                </div>
            )
        }
        return null;
    };

    renderMenuContent = () => {
        return this.state.menu.map((element, index) => {
            return (
                <Link to={element?.url} className={'menu-item' + (element?.active ? ' active' : '') } key={index} id={'item'+index} onClick={this.activateMenu}>
                    {element?.title}
                    {this.renderSubMenuContent(element.subelements)}
                </Link>
            )
        })
    }

    renderSocials = () => {
        if (this.state.socials?.length && this.state.scrolling)
            return (
                <div className='socials-container-header'>
                    {
                        this.state.socials.map((social, index) => {
                            return (
                                <a href={social?.url} target='_blank' className='social'>
                                    <img src={social?.icon} />
                                </a>
                            )
                        })
                    }
                </div>
            )
    }

    render() {
        return (
            <>
             <  script src="https://apps.elfsight.com/p/platform.js" defer></script>
                <div class="elfsight-app-0a4668b9-ae25-41be-9825-147f86ba5ee7"/>
                <div className={'header' + (this.props.isTransparent ? ' transparent' : '')}>
                    {this.renderMenuContainer()}
                </div>
                {this.renderSocials()}
                <div class="elfsight-app-7eceab0c-a876-48f8-aa26-cd19a642f342"></div>

            </>
        )
    }
}