import { Link } from 'gatsby-plugin-react-i18next'
import React, { Component } from 'react'
import '../../styles/scss/footer.scss'

export default class Footer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            footer: [
                {
                    header: 'Verse Estate',
                    isExpanded:false,
                    submenus:[
                        {
                            title:"About",
                            url:"/#about"
                        },
                        {
                            title:"Partner with us",
                            url:"/"
                        },
                        {
                            title:"Work here",
                            url:"/#join-us"
                        },
                        {
                            title:"Contact Us",
                            url:"/contact"
                        },
                    ]
                },
                {
                    header: 'Help',
                    isExpanded:false,
                    submenus:[
                        {
                            title:"Legal",
                            url:"/"
                        },
                        {
                            title:"Privacy policy",
                            url:"/"
                        },
                        {
                            title:"Terms of service",
                            url:"/terms-and-conditions"
                        },
                        {
                            title:"Cookies",
                            url:"/"
                        },
                        {
                            title:"FAQs",
                            url:"/faqs"
                        },
                    ]
                },
                {
                    header: 'Menu',
                    isExpanded:false,
                    submenus:[
                        {
                            title:"Verse world",
                            url:"/"
                        },
                        {
                            title:"3D Scanning",
                            url:"/#scanning-3D"
                        },
                        {
                            title:"3D Work",
                            url:"/#modeling-3D"
                        },
                        {
                            title:"AR/VR/MR",
                            url:"#ar-mr-vr"
                        },
                        {
                            title:"Motion Capture",
                            url:"/#motion-capture"
                        },
                        {
                            title:"Live Performance",
                            url:"/#live-performance"
                        },
                       /* {
                            title:"Event",
                            url:"/#events-physical"
                        },*/
                        {
                            title:"Verse Academy",
                            url:"/academy"
                        },
                    ]
                },
                {
                    header: 'Follow Us',
                    isExpanded:false,
                    submenus:[
                        {
                            title:"Twitter",
                            url:"https://x.com/VerseWorld",
                            newTab: true
                        },
                        {
                            title:"LinkedIn",
                            url:"https://ae.linkedin.com/company/verse-estate",
                            newTab: true
                        },
                        {
                            title:"Instagram",
                            url:"https://www.instagram.com/verseworld",
                            newTab: true
                        }
                    ]
                }
            ]
        }
    }

    renderSubMenus = (menuItem) => {
        return(
            menuItem.map((element) => {
                return(
                    <Link to={element.url} target={element?.newTab ? '_blank' : null} >{element.title}</Link>
                )
            })
        )
    }

    getSubmenusPropreties = (element) =>{
        if(element?.isExpanded){
            return({
                maxHeight:'500px',
                paddingTop: '30px',
                paddingBottom:'30px',
                opacity:'1',
                transform:'scale(1)'
            })
        }else{
            return({})
        }
    }

    expandMenuItem = (i) => {
        if(window.innerWidth < 769){
            
            let Modifiedfooter = this.state.footer.map((element,index) => {
                if(index==i){
                    element.isExpanded = !element.isExpanded;
                }
                return element
            })
            this.setState({
                footer:Modifiedfooter
            })
        }
    }

    renderFooterItems = () => {
        return(
            this.state.footer.map((item,index) => {
                return(
                    <div className={'links-container '.concat(item.isExpanded ? "expanded " : "")} key={index}>
                        <h3 onClick={() => this.expandMenuItem(index)}>{item.header}</h3>
                        <div className='links' 
                            style={this.getSubmenusPropreties(item)}
                        >
                            {this.renderSubMenus(item.submenus)}
                        </div>
                    </div>
                )
            })
        )
    }
    
    render() {
        return(
            <footer>
                <div className='footer-container'>
                    <div className='logo-container'>
                        <div className='logo'/>
                    </div>
                    {this.renderFooterItems()}
                </div>
                <div className='rights-reserved'>
                    © All rights are reserved - {new Date().getFullYear()}
                </div>
            </footer>
        )
    }
}
